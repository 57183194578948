import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-index11',
  templateUrl: './index11.component.html',
  styleUrls: ['./index11.component.scss']
})
/**
 * Index-11 component
 */
export class Index11Component implements OnInit {

  constructor() { }

  currentSection = 'home';

  ngOnInit(): void {
    document.getElementById('color-opt').setAttribute('href', 'assets/css/colors/purple.css');

    this.fetchData();

    let i = 1;
    setInterval(() => {
      if (i === 1) { (<HTMLImageElement>document.querySelector("#isSlideImage")).src = 'assets/images/slide-1.jpg'; }
      else if (i === 2) { (<HTMLImageElement>document.querySelector("#isSlideImage")).src = 'assets/images/slide-2.jpg'; }
      else { (<HTMLImageElement>document.querySelector("#isSlideImage")).src = 'assets/images/slide-3.jpg'; }
      if (i >= 3) { i = 0; }
      i++;
    }, 2500);
  }

  private fetchData() {
    let i = 1;
    setInterval(() => {
      // tslint:disable-next-line: max-line-length
      if (i === 1) { document.getElementById('text').innerHTML = 'We love make things amazing and simple'; }
      else if (i === 2) { document.getElementById('text').innerHTML = 'Create amazing landing page with Globing'; }
      else { document.getElementById('text').innerHTML = 'Perfact solution for small businesses'; }
      if (i >= 3) { i = 0; }
      i++;
    }, 2500);
  }
  /**
   * Window scroll method
   */
  // tslint:disable-next-line: typedef
  windowScroll() {
    const navbar = document.getElementById("navbar");
    if (
      document.body.scrollTop > 960 ||
      document.documentElement.scrollTop > 960
    ) {
      navbar.classList.add("nav-sticky");
      navbar.classList.add("small");
      document.getElementById("undefined-sticky-wrapper").classList.add("is-sticky")
      navbar.setAttribute("style", "position: fixed; top: 0px;");
    } else {
      navbar.classList.remove("nav-sticky");
      navbar.classList.remove("small");
      document.getElementById("undefined-sticky-wrapper").classList.remove("is-sticky")
      navbar.removeAttribute("style");
    }
  }

  /**
   * Toggle navbar
   */
  toggleMenu() {
    document.getElementById('navbarCollapse').classList.toggle('show');
  }

  /**
   * Section changed method
   * @param sectionId specify the current sectionID
   */
  onSectionChange(sectionId: string) {
    this.currentSection = sectionId;
  }
}
